export type RunEveryProps =
  | '15 minutes'
  | '30 minutes'
  | 'hour'
  | '3 hours'
  | '6 hours'
  | '12 hours'
  | 'day'
  | 'week'
  | 'month'
  | 'year'
  | 'monday'
  | 'friday';

export const getCronTime = (runEvery: RunEveryProps) => {
  switch (runEvery) {
    case '15 minutes':
      return '*/15 * * * *';
    case '30 minutes':
      return '*/30 * * * *';
    case 'hour':
      return '0 * * * *';
    case '3 hours':
      return '0 */3 * * *';
    case '6 hours':
      return '0 */6 * * *';
    case '12 hours':
      return '0 */12 * * *';
    case 'day':
      return '0 16 * * *';
    case 'week':
      return '0 15 * * 0';
    case 'month':
      return '0 15 1 * *';
    case 'year':
      return '0 15 1 1 *';
    case 'monday':
      return '0 15 * * 1';
    case 'friday':
      return '0 15 * * 5';
  }
};

export const timestampToAgo = (timestamp: string | number) => {
  const created_at = new Date(Number(timestamp));
  const daysAgo = Math.floor(
    (new Date().getTime() - created_at.getTime()) / (1000 * 3600 * 24)
  );
  const createdDate = created_at.getDate();
  const createdMonth = created_at.getMonth() + 1;
  const createdYear = created_at.getFullYear();

  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  if (
    createdDate === currentDate &&
    createdMonth === currentMonth &&
    createdYear === currentYear
  )
    return 'Vandaag';

  if (
    createdDate === currentDate - 1 &&
    createdMonth === currentMonth &&
    createdYear === currentYear
  )
    return 'Gisteren';

  if (daysAgo < 7) return `${daysAgo} dagen geleden`;
  if (daysAgo < 14) return '1 week geleden';
  if (daysAgo < 21) return '2 weken geleden';
  if (daysAgo < 28) return '3 weken geleden';
  if (daysAgo < 60) return '1 maand geleden';
  if (daysAgo < 90) return '2 maanden geleden';
  if (daysAgo < 120) return 'Meer dan 3 maanden geleden';

  return created_at.toLocaleDateString();
};
