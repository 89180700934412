'use client';
import React, { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

const pixelId = '469079955989396';

export const FacebookPixelEvents: React.FC<{ id?: string }> = ({
  id = pixelId,
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(id); //don't forget to change this
        ReactPixel.pageView();
      });
  }, [pathname, searchParams]);

  return null;
};

export const pixelEvent = async (
  event: string,
  data: Record<string, unknown>
) => {
  const { default: ReactPixel } = await import('react-facebook-pixel');
  ReactPixel.init(pixelId);
  ReactPixel.track(event, data);
};
